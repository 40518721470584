/* stilizirane na NAVBAR */

/* import na shrift za polzvane */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');



/* zvetovete napraveni kato promenlivi za po lesno izvikvane s VAR */
:root {
    --primary-blue: rgb(33, 141, 212);
    --primary-black: black;
    --primary-gray: #f8f9fa;
    --primary-light-brown: #cda45e;
}



body {
    font-family: "Roboto" sans-serif;
}


.colorLine {
    border-style: solid;
    border-width: 5px 0 0;
    border-color: #eab485;
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}



/* nav links*/

nav a.nav-link {
    font-weight: 600;
    letter-spacing: 0.037rem;
    font-size: 1.1rem;
}

nav ul li {
    text-transform: uppercase;
}


.navbar-nav>li>a {
    color: var(--primary-black) !important;
    border-bottom: 0.18rem solid var(--primary-gray);
}



/* korekzii za imeto na firmata/kompaniata */
.navbar-brand {
    font-family: "Tahoma" sans-serif;
    font-weight: 700;
    font-size: 2rem;

}

.d-inline-block {
    margin-right: 0.6rem !important;
}

/* podchertavane na razdela ot menuto pri izbirane */
.navbar-nav>li>a.active,
.navbar-nav>li>a.active:focus {
    border-bottom: 0.15rem solid var(--primary-blue);

}

/* podchertavane na razdela ot menuto pri pokazvane s mishkata */
.navbar-nav>li>a:hover {
    border-bottom: 0.15rem solid var(--primary-blue);
}

/* F-book icon */
.hover-effect-icon>a:hover {
    color: #1876f2;
}