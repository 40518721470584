@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
/* @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700'); */

/*--- задаване на какво покрива, цялостно покритие на саита, цялостна корекция на шрифта и задаване на цвят в саита --*/
html,
body {
  height: 100%;
  width: 100%;
  /* font-family: 'Poppins', sans-serif; */
  color: #222;
}


/* За нас с кашона пропартитата */
.forUS {
  padding-top: 2rem;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-weight: bolder;
  color: #fff;
  text-align: center;
}

.moreInfo {
  padding-bottom: 1rem;
  font-weight: bold;
  font-size: 1.1rem;
  color: #fff;
  text-align: center;
}

.more-info-text {
  padding-bottom: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #fff;
  text-align: center;
}

.back-color-info-text-welcome {
  background-color: #7e6a52;
}

/*        -------------------------------------------------------------------- */


.card-adjustments {
  margin: 0.3rem;
}



/* -------------------------------------------------------------------- */

/*efectra pri pokazvane varhu kartinkata da se ogolemqva */
.card-img-top-edit {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
}

.card-img-top-edit:hover {
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
}

/* za da ne se ogolemqva varhu drugite sasedni elementi a samo v ramkata si izpolzvame tova */
.zoooom {
  overflow: hidden;
}

/* CARD text props */
#text-title-props {
  text-align: center;
  color: #363b46;
}

#text-text-card-props {
  text-indent: 0.6rem;
  color: #363b46;
}
/* stilizirane na NAVBAR */

/* import na shrift za polzvane */



/* zvetovete napraveni kato promenlivi za po lesno izvikvane s VAR */
:root {
    --primary-blue: rgb(33, 141, 212);
    --primary-black: black;
    --primary-gray: #f8f9fa;
    --primary-light-brown: #cda45e;
}



body {
    font-family: "Roboto" sans-serif;
}


.colorLine {
    border-style: solid;
    border-width: 5px 0 0;
    border-color: #eab485;
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}



/* nav links*/

nav a.nav-link {
    font-weight: 600;
    letter-spacing: 0.037rem;
    font-size: 1.1rem;
}

nav ul li {
    text-transform: uppercase;
}


.navbar-nav>li>a {
    color: black !important;
    color: var(--primary-black) !important;
    border-bottom: 0.18rem solid #f8f9fa;
    border-bottom: 0.18rem solid var(--primary-gray);
}



/* korekzii za imeto na firmata/kompaniata */
.navbar-brand {
    font-family: "Tahoma" sans-serif;
    font-weight: 700;
    font-size: 2rem;

}

.d-inline-block {
    margin-right: 0.6rem !important;
}

/* podchertavane na razdela ot menuto pri izbirane */
.navbar-nav>li>a.active,
.navbar-nav>li>a.active:focus {
    border-bottom: 0.15rem solid rgb(33, 141, 212);
    border-bottom: 0.15rem solid var(--primary-blue);

}

/* podchertavane na razdela ot menuto pri pokazvane s mishkata */
.navbar-nav>li>a:hover {
    border-bottom: 0.15rem solid rgb(33, 141, 212);
    border-bottom: 0.15rem solid var(--primary-blue);
}

/* F-book icon */
.hover-effect-icon>a:hover {
    color: #1876f2;
}
/*font-family: "Poppins", sans-serif;
background: #cda45e;

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.ftco-section-parallax {
  position: relative; }
  .ftco-section-parallax .parallax-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    padding: 4em 0; }
  .ftco-section-parallax .heading-section-white h2 {
    font-weight: 400 !important; }


    .ftco-section {
      padding: 7em 0;
      position: relative; }
      @media (max-width: 767.98px) {
        .ftco-section {
          padding: 6em 0; } }




          .dbox {
            width: 100%;
            margin-bottom: 25px;
            padding: 0 20px; }
            @media (min-width: 768px) {
              .dbox {
                margin-bottom: 0;
                padding: 0; } }
            .dbox p {
              margin-bottom: 0; }
              .dbox p span {
                font-weight: 500;
                color: #000000; }
              .dbox p a {
                color: #e4c197; }
            .dbox .icon {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background: #cda45e;
              margin: 0 auto;
              margin-bottom: 20px; }
              .dbox .icon span {
                font-size: 30px;
                color: #fff; }
            .dbox .text {
              width: 100%; }     
              
              

 .icon-video span {
    font-size: 20px;
    color: #fff; }

.wrapper {
width: 100%; } 





.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }









/**       snimka contacti */

.hero-wrap {
  width: 100%;
  height: 100%;
  position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative; }
  @media (max-width: 1199.98px) {
    .hero-wrap {
      background-position: center center !important; } }
  .hero-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .4;
    background: #000000; }
  .hero-wrap.hero-wrap-2 {
    height: 400px; }
    .hero-wrap.hero-wrap-2 .overlay {
      opacity: .5; }
    .hero-wrap.hero-wrap-2 .slider-text {
      height: 400px; }
  .hero-wrap .slider-text h1 {
    font-size: 70px;
    color: #fff;
    line-height: 1.2;
    font-weight: 800;
    font-family: "Poppins", Arial, sans-serif;
    text-transform: uppercase; }
    @media (max-width: 991.98px) {
      .hero-wrap .slider-text h1 {
        font-size: 40px; } }
    .hero-wrap .slider-text h1 span {
      color: #fdbe34; }
  .hero-wrap .slider-text .subheading {
    font-size: 15px;
    color: #fdbe34;
    text-transform: uppercase;
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 800;
    letter-spacing: 2px; }
  .hero-wrap .slider-text p {
    font-size: 18px;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.7); }
    .hero-wrap .slider-text p strong {
      font-weight: 700; }
      .hero-wrap .slider-text p strong a {
        color: #000000; }
  .hero-wrap .slider-text .btn-custom {
    font-size: 16px;
    font-weight: 500;
    color: #fff; }
  .hero-wrap .slider-text .breadcrumbs {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    z-index: 99; }
    .hero-wrap .slider-text .breadcrumbs span {
      color: rgba(255, 255, 255, 0.5); }
      .hero-wrap .slider-text .breadcrumbs span i {
        color: rgba(255, 255, 255, 0.3); }
      .hero-wrap .slider-text .breadcrumbs span a {
        color: rgba(255, 255, 255, 0.7); }
        .hero-wrap .slider-text .breadcrumbs span a:hover, .hero-wrap .slider-text .breadcrumbs span a:focus {
          color: #fdbe34; }
          .hero-wrap .slider-text .breadcrumbs span a:hover i, .hero-wrap .slider-text .breadcrumbs span a:focus i {
            color: #fdbe34; }
  .hero-wrap .slider-text .bread {
    font-weight: 700 !important; }


 /*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

    /*  footer */
    #footer .copyright {
      text-align: center;
      padding-top: 15px;
    }

    #footer .credits {
      padding-top: 10px;
      text-align: center;
      font-size: 13px;
    }


   
#footer {
  padding: 0 0 15px 0;
  font-size: 14px;
}

#footer .footer-top .footer-info {
  margin-bottom: 15px;
}


  



/* strukturirane na gallery */
.gallery {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}

/* sazdavane na raz4i4kata */
.gallery .pics {
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

/*efecta zamaglqne pri pokazvane varhu snimkata */
.gallery .pics:hover {
    -webkit-filter: opacity(.8);
            filter: opacity(.8);
}

/* razoredelenie na snimkite pri sredna rezuluzia */
@media (max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        column-count: 2;
    }
}

/*razpredelenie na snimkite pri malka rezuluzia */ 
@media (max-width: 480px) {
    .gallery {
        -webkit-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        column-width: 100%;
    }
}

/* MODEl -- OpenImage Styles */ 


.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    transition: opacity .4s ease, visibility .4s ease, -webkit-transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    overflow: hidden;
    z-index: 999;
}



.model.open{
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);    
}


.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

/* button za close na otvorenata snimka */
.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    background-color: rgb(255, 255, 255);
    color: #000000;
    cursor: pointer;
}

.error-page{
    font-size: 3rem;
    padding: 0.5rem;
    text-align: center;
    margin-top: 9rem;
    margin-bottom: 9rem;
    font-weight: bold;
}

.error-page-404{
    font-size: 6rem;
}
/* */


/* total container ID */
#three-five-layer-cont {
  padding-top: 0rem;
}

/* text property on top of the image */
#element-text-type {
  text-align: center;
  padding: 0.5rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  padding-bottom: 2rem;
  font-size: 1.5rem;
}


/* text info description */
#material-text-info-title {
  text-align: center;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 1.4rem;
}

/* text  description correct */
#text-custom-description {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}





/* Sink image effect*/
.d-block-image-cardboard-boxes {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}

.d-block-image-cardboard-boxes:hover,
.d-block-image-cardboard-boxes:focus,
.d-block-image-cardboard-boxes:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

/* */



/* border divider */
.b-example-divider {
  height: 1rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

@media (min-width: 992px) {
  .rounded-lg-3 {
    border-radius: .3rem;
  }
}
/* */ 
/* golabale CSS Style za zelia sait */


