.error-page{
    font-size: 3rem;
    padding: 0.5rem;
    text-align: center;
    margin-top: 9rem;
    margin-bottom: 9rem;
    font-weight: bold;
}

.error-page-404{
    font-size: 6rem;
}