

/* strukturirane na gallery */
.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}

/* sazdavane na raz4i4kata */
.gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

/*efecta zamaglqne pri pokazvane varhu snimkata */
.gallery .pics:hover {
    filter: opacity(.8);
}

/* razoredelenie na snimkite pri sredna rezuluzia */
@media (max-width: 991px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

/*razpredelenie na snimkite pri malka rezuluzia */ 
@media (max-width: 480px) {
    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}

/* MODEl -- OpenImage Styles */ 


.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}



.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);    
}


.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

/* button za close na otvorenata snimka */
.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    background-color: rgb(255, 255, 255);
    color: #000000;
    cursor: pointer;
}
