/* */


/* total container ID */
#three-five-layer-cont {
  padding-top: 0rem;
}

/* text property on top of the image */
#element-text-type {
  text-align: center;
  padding: 0.5rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  padding-bottom: 2rem;
  font-size: 1.5rem;
}


/* text info description */
#material-text-info-title {
  text-align: center;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 1.4rem;
}

/* text  description correct */
#text-custom-description {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}





/* Sink image effect*/
.d-block-image-cardboard-boxes {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.d-block-image-cardboard-boxes:hover,
.d-block-image-cardboard-boxes:focus,
.d-block-image-cardboard-boxes:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

/* */



/* border divider */
.b-example-divider {
  height: 1rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

@media (min-width: 992px) {
  .rounded-lg-3 {
    border-radius: .3rem;
  }
}