/*font-family: "Poppins", sans-serif;
background: #cda45e;

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.ftco-section-parallax {
  position: relative; }
  .ftco-section-parallax .parallax-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    padding: 4em 0; }
  .ftco-section-parallax .heading-section-white h2 {
    font-weight: 400 !important; }


    .ftco-section {
      padding: 7em 0;
      position: relative; }
      @media (max-width: 767.98px) {
        .ftco-section {
          padding: 6em 0; } }




          .dbox {
            width: 100%;
            margin-bottom: 25px;
            padding: 0 20px; }
            @media (min-width: 768px) {
              .dbox {
                margin-bottom: 0;
                padding: 0; } }
            .dbox p {
              margin-bottom: 0; }
              .dbox p span {
                font-weight: 500;
                color: #000000; }
              .dbox p a {
                color: #e4c197; }
            .dbox .icon {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background: #cda45e;
              margin: 0 auto;
              margin-bottom: 20px; }
              .dbox .icon span {
                font-size: 30px;
                color: #fff; }
            .dbox .text {
              width: 100%; }     
              
              

 .icon-video span {
    font-size: 20px;
    color: #fff; }

.wrapper {
width: 100%; } 





.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }









/**       snimka contacti */

.hero-wrap {
  width: 100%;
  height: 100%;
  position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative; }
  @media (max-width: 1199.98px) {
    .hero-wrap {
      background-position: center center !important; } }
  .hero-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .4;
    background: #000000; }
  .hero-wrap.hero-wrap-2 {
    height: 400px; }
    .hero-wrap.hero-wrap-2 .overlay {
      opacity: .5; }
    .hero-wrap.hero-wrap-2 .slider-text {
      height: 400px; }
  .hero-wrap .slider-text h1 {
    font-size: 70px;
    color: #fff;
    line-height: 1.2;
    font-weight: 800;
    font-family: "Poppins", Arial, sans-serif;
    text-transform: uppercase; }
    @media (max-width: 991.98px) {
      .hero-wrap .slider-text h1 {
        font-size: 40px; } }
    .hero-wrap .slider-text h1 span {
      color: #fdbe34; }
  .hero-wrap .slider-text .subheading {
    font-size: 15px;
    color: #fdbe34;
    text-transform: uppercase;
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 800;
    letter-spacing: 2px; }
  .hero-wrap .slider-text p {
    font-size: 18px;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.7); }
    .hero-wrap .slider-text p strong {
      font-weight: 700; }
      .hero-wrap .slider-text p strong a {
        color: #000000; }
  .hero-wrap .slider-text .btn-custom {
    font-size: 16px;
    font-weight: 500;
    color: #fff; }
  .hero-wrap .slider-text .breadcrumbs {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    z-index: 99; }
    .hero-wrap .slider-text .breadcrumbs span {
      color: rgba(255, 255, 255, 0.5); }
      .hero-wrap .slider-text .breadcrumbs span i {
        color: rgba(255, 255, 255, 0.3); }
      .hero-wrap .slider-text .breadcrumbs span a {
        color: rgba(255, 255, 255, 0.7); }
        .hero-wrap .slider-text .breadcrumbs span a:hover, .hero-wrap .slider-text .breadcrumbs span a:focus {
          color: #fdbe34; }
          .hero-wrap .slider-text .breadcrumbs span a:hover i, .hero-wrap .slider-text .breadcrumbs span a:focus i {
            color: #fdbe34; }
  .hero-wrap .slider-text .bread {
    font-weight: 700 !important; }


 /*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

    /*  footer */
    #footer .copyright {
      text-align: center;
      padding-top: 15px;
    }

    #footer .credits {
      padding-top: 10px;
      text-align: center;
      font-size: 13px;
    }


   
#footer {
  padding: 0 0 15px 0;
  font-size: 14px;
}

#footer .footer-top .footer-info {
  margin-bottom: 15px;
}


  
