/* @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700'); */

/*--- задаване на какво покрива, цялостно покритие на саита, цялостна корекция на шрифта и задаване на цвят в саита --*/
html,
body {
  height: 100%;
  width: 100%;
  /* font-family: 'Poppins', sans-serif; */
  color: #222;
}


/* За нас с кашона пропартитата */
.forUS {
  padding-top: 2rem;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-weight: bolder;
  color: #fff;
  text-align: center;
}

.moreInfo {
  padding-bottom: 1rem;
  font-weight: bold;
  font-size: 1.1rem;
  color: #fff;
  text-align: center;
}

.more-info-text {
  padding-bottom: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #fff;
  text-align: center;
}

.back-color-info-text-welcome {
  background-color: #7e6a52;
}

/*        -------------------------------------------------------------------- */


.card-adjustments {
  margin: 0.3rem;
}



/* -------------------------------------------------------------------- */

/*efectra pri pokazvane varhu kartinkata da se ogolemqva */
.card-img-top-edit {
  transform: scale(1);
  transition: transform 0.5s ease;
  cursor: pointer;
}

.card-img-top-edit:hover {
  transform: scale(1.8);
}

/* za da ne se ogolemqva varhu drugite sasedni elementi a samo v ramkata si izpolzvame tova */
.zoooom {
  overflow: hidden;
}

/* CARD text props */
#text-title-props {
  text-align: center;
  color: #363b46;
}

#text-text-card-props {
  text-indent: 0.6rem;
  color: #363b46;
}